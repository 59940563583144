import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpParams, HttpRequest } from "@angular/common/http";
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppService } from './app.service';
import { LoadingService } from './loading.service';
import { EnumService } from './enum.service';
import { DeviceEnums } from '../classes/deviceEnums';



@Injectable({
  providedIn: 'root'
})
export class DataService {
  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, public appService: AppService, public enumService: EnumService, private router: Router) {
    this.setEnumsAndCenter();
  }
  readonly BaseURI = '';

  setEnumsAndCenter() {
    if (localStorage.getItem('token') != undefined && localStorage.getItem('token') != null) {
      //console.log('tokeeeeen');
      //console.log(localStorage.getItem('token'));
      if (!this.appService.RunEnumsList) {
        //    console.log(this.appService.enumCannulationSites);
        this.getall('Enums/GetRunEnums').subscribe(
          (res: any) => {
            //console.log(res);
            this.appService.RunEnumsList = res.data;
            this.appService.MLReplacementCodes = res.data.MLReplacementCodes;
            this.appService.BPReplacementCodes = res.data.BPReplacementCodes;
            this.appService.enumCannulationSites = res.data.CannulationSites;
            this.appService.CReplacementCodes = res.data.CReplacementCodes;

            this.appService.enumComplicationCodes = res.data.ComplicationCodes;
          },
          err => {
            //redirect to login page when token is missing user rolles            
            //console.log(err);
            localStorage.removeItem('cultureSettings');
            localStorage.removeItem('unitSystem');
            localStorage.removeItem('cultureOptionSelected');
            localStorage.removeItem('centerName');
            localStorage.removeItem('centerId');
            localStorage.removeItem('token');
            localStorage.removeItem('userName');
            localStorage.removeItem('TestXMLLogin');

            this.router.navigateByUrl('/login');
          }
        )

        this.setDeviceEnums();
      } else {
        //console.log('cant get devices data');
        //console.log(localStorage.getItem('token'));
      }

      if (localStorage.getItem('TestXMLLogin') == 'true') {
        this.appService.TestXMLLogin = true;
      } else {
        this.appService.TestXMLLogin = false;
      }

      if (!this.appService.Center) {
        this.getall('Users/GetUserCenter').subscribe(
          (res: any) => {
            this.appService.Center = res.data;
            if (this.appService.Center.GoLiveAfterDate) {
              this.appService.CenterIsXML = true;
            }
            //console.log(this.appService.Center);
          },
          err => {
            console.log(err);
          }
        )
      }
    } else {

    }
  }

  setDeviceEnums() {
    this.getall('Enums/GetEquipmentDevicesAndManufacturers').subscribe(
      (res: any) => {
        //console.log(res);
        this.enumService.DeviceEnums = new DeviceEnums();
        this.enumService.DeviceEnums.enumAllMembraneLungs = res.data.AllMembraneLungDevicesList;
        this.enumService.DeviceEnums.enumMembraneLungs = res.data.MembraneLungDevicesList;
        this.enumService.DeviceEnums.enumMembraneLungsManufacturers = res.data.MembraneLungManufacturersList;
        this.enumService.DeviceEnums.enumAllBloodPumps = res.data.AllPumpDevicesList;
        this.enumService.DeviceEnums.enumBloodPumps = res.data.PumpDevicesList;
        this.enumService.DeviceEnums.enumBloodPumpsManufacturers = res.data.PumpManufacturersList;
        this.enumService.DeviceEnums.enumAllHemofilters = res.data.AllHemofilterDevicesList;
        this.enumService.DeviceEnums.enumHemofilters = res.data.HemofilterDevicesList;
        this.enumService.DeviceEnums.enumHemofiltersManufacturers = res.data.HemofilterManufacturersList;
        this.enumService.DeviceEnums.enumAllTemperatureRegulations = res.data.AllTemperatureRegulationDevicesList;
        this.enumService.DeviceEnums.enumTemperatureRegulations = res.data.TemperatureRegulationDevicesList;
        this.enumService.DeviceEnums.enumTemperatureRegulationsManufacturers = res.data.TemperatureRegulationManufacturersList;
        this.enumService.DeviceEnums.enumAllCannulas = res.data.AllCannulaDevicesList;
        this.enumService.DeviceEnums.enumCannulas = res.data.CannulaDevicesList;
        this.enumService.DeviceEnums.enumCannulasManufacturers = res.data.CannulaManufacturersList;
        this.enumService.DeviceEnums.membraneLungsCenterSpecific = res.data.CSMembraneLungDevicesList;
        this.enumService.DeviceEnums.bloodPumpsCenterSpecific = res.data.CSBloodPumpDevicesList;
        this.enumService.DeviceEnums.hemofiltersCenterSpecific = res.data.CSHemofilterDevicesList;
        this.enumService.DeviceEnums.temperatureRegulationsCenterSpecific = res.data.CSTemperatureRegulationDevicesList;
        this.enumService.DeviceEnums.cannulasCenterSpecific = res.data.CSCannulaDevicesList;

        //console.log(this.appService.cannulasCenterSpecific);
      },
      err => {
        console.log(err);
      }
    );
  };

  getall(path: string) {
    return this.http.get(this.BaseURI + '/api/' + path, { headers: this.getHeaders() });
  }
  
  get(path: string, paramName: string, obj?: any, customHeaders?: any) {
    if (paramName != null && obj != null) {
      const params = new HttpParams().append(paramName, obj);
      return this.http.get(this.BaseURI + '/api/' + path, { headers: this.getHeaders(customHeaders), params: params });
    } else {
      //return this.http.get(this.BaseURI + '/api/' + path);06-23-2023
      return this.http.get(this.BaseURI + '/api/' + path, { headers: this.getHeaders(customHeaders) });
    }
  }

  getRunDataDump(path: string, obj: any) {
    return this.http.put(this.BaseURI + '/api/' + path, obj, { responseType: "blob", headers: this.getHeaders() });
  }
  //getFile2(path: string, paramName: string, obj?: any) {

  //  if (paramName != null && obj != null) {
  //    const params = new HttpParams().append(paramName, obj);
  //    return this.http.get(this.BaseURI + '/api/' + path, { responseType: "blob", headers: this.getHeaders(), params: params });
  //  } else {
  //    //return this.http.get(this.BaseURI + '/api/' + path);06-23-2023
  //    return this.http.get(this.BaseURI + '/api/' + path, { responseType: "blob" });
  //  }

  //}

  getFile(path: string, paramName: string, obj?: any) {

    if (paramName != null && obj != null) {
      const params = new HttpParams().append(paramName, obj);
      return this.http.get(this.BaseURI + '/api/' + path, { responseType: "blob", headers: this.getHeaders(), params: params });
    } else {
      //return this.http.get(this.BaseURI + '/api/' + path);06-23-2023
      return this.http.get(this.BaseURI + '/api/' + path, { responseType: "blob" });
    }
  
  }
  create(path: string, obj: any) {
    return this.http.post(this.BaseURI + '/api/' + path, obj, { headers: this.getHeaders() });
  }

  uploadXMLImport(path: string, obj: any, fileName: string, sendXMLValidationToEmail: boolean, sendXMLConfirmationToEmail: boolean) {
    return this.http.post(this.BaseURI + '/api/' + path, obj, { headers: this.getXMLHeaders(fileName, sendXMLValidationToEmail, sendXMLConfirmationToEmail) });
  }

  update(path: string, obj: any, customHeaders?: any) {
    return this.http.put(this.BaseURI + '/api/' + path, obj, { headers: this.getHeaders(customHeaders) });
  }

  updateModes(path: string, obj: any, deleteDiagnoses: string, resetJvCaLR: string, resetCDHfields: string) {
    return this.http.put(this.BaseURI + '/api/' + path, obj, { headers: this.getModesHeaders(deleteDiagnoses, resetJvCaLR, resetCDHfields) });
  }

  updateSingleParam(path: string, paramName: string, obj: any) {
    const params = new HttpParams().append(paramName, obj);
    return this.http.put(this.BaseURI + '/api/' + path, obj, { headers: this.getHeaders(), params: params });
  }

  delete(path: string, paramName: string, obj?: any) {
    var result = confirm("Are you sure you want to delete?");
    if (result) {
      const params = new HttpParams().append(paramName, obj);
      return this.http.delete(this.BaseURI + '/api/' + path, { headers: this.getHeaders(), params: params });
    }
  }

  login(obj: any) {
    if (!obj.TestXMLLogin) {
      if (location.hostname != 'localhost') {
        return this.http.post(this.BaseURI + '/api/users/LoginAuth0', obj, { headers: this.getHeaders() });
      } else {
        return this.http.post(this.BaseURI + '/api/users/LoginLocalHostAuth0', obj, { headers: this.getHeaders() });
      }
    } 
  }

  loginSecret(obj: any) {
    if (location.hostname != 'localhost') {//should update to set .elso cookie for client
      return this.http.post(this.BaseURI + '/api/users/LoginLocalHost', obj, { headers: this.getHeaders() });            
    } else {
      return this.http.post(this.BaseURI + '/api/users/LoginLocalHost', obj, { headers: this.getHeaders() });
    }
  }


  loginTestXML(obj: any) {
    //if (location.hostname != 'localhost') {
      return this.http.post(this.BaseURI + '/api/users/LoginAuth0TestXML', obj, { headers: this.getHeaders() });
    //}
    //else {
    //  return this.http.post(this.BaseURI + '/api/users/LoginLocalHostAuth0', obj, { headers: this.getHeaders() });
    //}
  }
  register(obj: any) {
    return this.http.post(this.BaseURI + '/api/users/register', obj);
  }
  logout() {
    return this.http.post(this.BaseURI + '/api/users/Logout', null);
  }
  logoutDOMO() {
    return this.http.post(this.BaseURI + '/api/users/LogoutDOMO', null);
  }

  getHeaders(customHeaders?: any): HttpHeaders {
    var objForHeader = {
      'RunId': this.appService.Run ? this.appService.Run.RunId : '',
      'RunNo': this.appService.Run ? String(this.appService.Run.RunNo) : '',
      'PatientId': this.appService.Patient ? this.appService.Patient.PatientId : '',
      'UniqueId': this.appService.Patient ? this.appService.Patient.UniqueId : '',
      'CenterId': this.appService.Center ? this.appService.Center.CenterId : '',
      'ClientDateTime': String(-(new Date().getTimezoneOffset() / 60)),
      //'auth0token': localStorage.getItem('auth0token') ? localStorage.getItem('auth0token') : ''
    };

    if (customHeaders) {
      Object.keys(customHeaders).forEach(function (key) {
        objForHeader[key] = customHeaders[key];
      });
    }

    var headers = new HttpHeaders(objForHeader);

    return headers;
  }

  getModesHeaders(DeleteDiagnoses: string, ResetJvCaLR: string, ResetCDHfields: string): HttpHeaders {
    return new HttpHeaders({
      'RunId': this.appService.Run ? this.appService.Run.RunId : '',
      'RunNo': this.appService.Run ? String(this.appService.Run.RunNo) : '',
      'PatientId': this.appService.Patient ? this.appService.Patient.PatientId : '',
      'UniqueId': this.appService.Patient ? this.appService.Patient.UniqueId : '',
      'CenterId': this.appService.Center ? this.appService.Center.CenterId : '',
      'ClientDateTime': String(-(new Date().getTimezoneOffset() / 60)),
      'DeleteDiagnoses': DeleteDiagnoses,
      'ResetJvCaLR': ResetJvCaLR,
      'ResetCDHfields': ResetCDHfields
    });
  }

  getXMLHeaders(fileName: string, sendXMLValidationToEmail: boolean, sendXMLConfirmationToEmail: boolean): HttpHeaders {
    /*console.log(String(-(new Date().getTimezoneOffset() / 60)));*/
    return new HttpHeaders({
      'RunId': this.appService.Run ? this.appService.Run.RunId : '',
      'RunNo': this.appService.Run ? String(this.appService.Run.RunNo) : '',
      'PatientId': this.appService.Patient ? this.appService.Patient.PatientId : '',
      'UniqueId': this.appService.Patient ? this.appService.Patient.UniqueId : '',
      'CenterId': this.appService.Center ? this.appService.Center.CenterId : '',
      'SendXMLValidationToEmail': String(sendXMLValidationToEmail),
      'SendXMLConfirmationToEmail': String(sendXMLConfirmationToEmail),
      'FileName': fileName,
      'ClientDateTime': String(-(new Date().getTimezoneOffset() / 60))
    });
  }
  
}

